import React, { useState } from "react";
import { useField, useForm } from "@formily/react";
import FormilyMenu, { FormilyMenuProps } from "./Menu";
import { createBehavior, createResource } from "@designable/core";
import { DnFC } from "@designable/react";
import { createBaseSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";
import { removeEmptyChildren, clickEventToProps, getArrChildrenKey, getOpenKeys } from "../../../service";
import './styles.less'
import { useEffect } from "react";
import { Icon } from "../../setters";

const fillIcon = (arr) => {
  arr.forEach(item => {
    if (item.children) {
      item.children = fillIcon(item.children)
    }
    if (item.icon) {
      item.icon = <Icon name={item.icon} />
    }
  });
  return arr
}

export const Menu: DnFC<FormilyMenuProps> = ({
  items = [],
  defaultChecked,
  style,
  ...props
}) => {
  const field = useField();
  const form: any = useForm();
  const [current, setCurrent] = useState(undefined);
  const [newItems, setItems] = useState([]);
  const [eventArr, setEventArr] = useState([]);

  let editprops: any = {};

  if ((props['data-designer-node-id'] || field.decoratorProps["data-designer-node-id"]) && props.mode != 'horizontal') {
    const openKeys = getArrChildrenKey(items)
    editprops.defaultOpenKeys = openKeys
  } else if(props.mode != 'horizontal') {
    editprops.defaultOpenKeys = getOpenKeys(items, defaultChecked)
  }

  useEffect(() => {
    setCurrent(defaultChecked)
  }, [defaultChecked]);

  useEffect(() => {
    const [newItem, clickArr] = removeEmptyChildren(items)
   
    const newItems = fillIcon(newItem)
    setEventArr(clickArr)
    setItems(newItems)
  }, [items])

  const handleClick = (e) => {
    const currentKey = e.key;
    const currentItem = eventArr.find(item => item.key == currentKey)
    
    setCurrent(currentKey)
    if (!currentItem?.clickProps?.defaultAction.actionType) return;
    setTimeout(() => {
      let clickProps: any = clickEventToProps(currentItem.clickProps, form);
      clickProps.onClick()
    }, 0)
  }

  return (
    <div
      data-designer-node-id={props["data-designer-node-id"]}
      style={ style ? style : {
        maxWidth: props.mode != 'horizontal' ? '256px' : ''
      }}
    >
      {
        items && items.length > 0 ? <FormilyMenu style={style} selectedKeys={current} items={newItems} className="dn-menu" {...props} {...editprops} onClick={handleClick}/> :
        <div className="dn-menu-empty">
          无数据
        </div>
      }
      
    </div>
  );
};

Menu.Behavior = createBehavior({
  name: "Menu",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "Menu",
  designerProps: {
    propsSchema: createBaseSchema(AllSchemas.Menu),
  },
  designerLocales: AllLocales.Menu,
});

Menu.Resource = createResource({
  icon: 'MenuSource',
  elements: [
    {
      componentName: "Field",
      props: {
        type: "string",
        "x-component": "Menu",
        "x-component-props": {
          mode: 'horizontal',
          defaultChecked: 'menu1',
          items: [
            {
              key: 'menu1',
              label: '菜单 1',
              clickProps: {},
              icon: 'AppstoreOutlined',
              children: []
            },{
              key: 'menu2',
              label: '菜单 2',
              clickProps: {},
              icon: 'AppstoreOutlined',
              children: []
            },{
              key: 'menu3',
              label: '菜单 3',
              clickProps: {},
              icon: 'AppstoreOutlined',
              children: []
            }
          ]
        }
      },
    },
  ],
});
